import * as animationData from '@/assets/lf20_g3epl9yu.json'

import Lottie from 'react-lottie'

export default function Custom404(): JSX.Element {
  return (
    <div
      className="d-flex justify-content-center align-items-center flex-column"
      style={{ height: '100%' }}
    >
      <Lottie
        options={{
          animationData,
          loop: false,
          autoplay: true
        }}
        height={300}
        width={300}
      />
      <h4>Página não encontrada</h4>
    </div>
  )
}
